import React, { useEffect, useState, memo } from "react";
import axios from 'axios'
import { CardCampo } from "components/CardCampo";
import { useCampos } from "../../hooks/useCampos";
import Loader from "components/Loader";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
export const Campos = () => {
    const { campos, loading } = useCampos()
    return (
        <>
            {loading ? <Loader /> : (
                <>
                <section className="relative bvlock h-500-px">
                    <div className="absolute top-0 w-full h-full bg-center bg-cover">
                <img alt="..." className="absolute top-0 w-full h-full bg-center bg-cover" src={require('assets/img/team-2-800x800.jpg').default} />
                            <span
                                id="blackOverlay"
                                className="w-full h-full absolute opacity-50 bg-black"
                            ></span>
                </div>
                        <div
                            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                            style={{ transform: "translateZ(0)" }}
                        >
                            <svg
                                className="absolute bottom-0 overflow-hidden"
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="text-blueGray-200 fill-current"
                                    points="2560 0 2560 100 0 100"
                                ></polygon>
                            </svg>
                        </div>
                    </section>
                        <section className="relative py-3 bg-blueGray-200">
                        <div className="container mx-auto px-100 mb-5">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                                <div className="px-6">

                                    <div className="text-center mt-12">
                                        <h1 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                                        Campos del proyecto {" "}

                                            <i className="fa-solid fa-tractor"></i>
                                        </h1>
                                        <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                                            Campos relacionados al proyecto los cuales tienen sensores que monitorean las plantaciones de cerezas en tiempo real
                                        </div>
                                        <div className="mr-4 p-3 text-center">
                                            <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                                            </span>
                                            <span className="text-sm text-blueGray-400">
                                            </span>
                                        </div>
                                        <div className="mb-5 text-blueGray-600 mt-3">
                                        <Link
                                                    className="btn btn-outline primary bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                    to={`/graficos`}
                                                    role="button"
                                                >
                                                    <i className="fa fa-chart-line ml-3"></i>
                                                    {" "}Ver todos los graficos
                                                    
                                                </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row container-fluid mb-6">
                            <Card >
                                <Card.Header >
                                    Listado de Campos 
                                </Card.Header>
                                <Card.Body className="bg-danger">
                                {campos.length > 1
                                && (campos.map((campo, id) => (
                                    (
                                        <>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <CardCampo
                                                    key={id}
                                                    id={id}
                                                    campo={campo}
                                                />
                                            </div>
                                        </>
                                    )
                                )
                                ))}
                                </Card.Body>
                            </Card>
                            
                        </div>
                        </section>

                        </>
                        
            )}
        </>
    )
}
