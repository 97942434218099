import React, { memo } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import "../../assets/styles/noticias.css";


function Noticia({ noticia }) {
  const MAX_LENGTH = 500;

  return (
    <div className="card">
      <div className="card-body h-100 ">
        <div className="card-img">
          <img src={noticia.imagen} className="img img-responsive" alt="" />
        </div>
        <div className="card-desc">
          <h3>{noticia.titulo}</h3>
          <p>

            {noticia.subtitulo && noticia.sub_titulo.length > MAX_LENGTH ? (
              <div>
                {ReactHtmlParser(`${noticia.sub_titulo.substring(0, 100)}...`)}
              </div>
            ) : (
              <p>{ReactHtmlParser(noticia.sub_titulo)}</p>
            )}
          </p>

        </div>
      </div>

      <div className="card-footer">
        <Link to={`/noticia/${noticia.id}`} className="btn-card">
          Leer
        </Link>
      </div>

    </div>

  );
}

export default memo(Noticia);
