import { Button, Tab, Tabs, Col, Row, Card } from 'react-bootstrap';
import { Iframe } from './Iframe';
import { memo, useCallback } from 'react'
import ReactHtmlParser from "react-html-parser";

export const GraficoNavLink = memo(({ tipos, graficos, nombre }) => {

    const colResumen = (grafico) => (<Col md={4} sm={4}>
        <Card
            bg="dark"
            key="dark"
            text='light'
            className="mb-2 border-primary"

        >
            <Card.Header>{grafico.medida.nombre}</Card.Header>
            <Card.Body>
                <Card.Title className="text-white">Descripcion</Card.Title>
                <Card.Text>
                    {ReactHtmlParser(grafico.medida.descripcion)}
                </Card.Text>
                <Button className="bg-blueGray-800 mt-3 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    onClick={() => window.open(grafico.url, "_blank")}
                    >
                    Ver en pantalla completa
                </Button>
            </Card.Body>
        </Card>
    </Col>)

    const colGrafico = grafico => (<Col md={8} sm={8}>
        <Iframe url={grafico.url} />
    </Col>)

    const loadTabWithGraphs = useCallback(() => {
        return tipos.map((tipo, id) =>
            <Tab eventKey={id} title={tipo.nombre} className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
                {graficos.map((grafico, idGrafico) => grafico.medida.tipo.nombre == tipo.nombre &
                    grafico.campo.nombre == nombre ? (
                    <>
                        <Row>
                            {idGrafico % 2 == 0
                                ? (
                                    <>
                                        {colResumen(grafico)}
                                        {colGrafico(grafico)}
                                    </>)
                                :
                                (
                                    <>
                                        {colGrafico(grafico)}
                                        {colResumen(grafico)}
                                        
                                    </>)
                            }
                            <hr className='mt-3 mb-3 text text-light' />
                        </Row>
                    </>
                ) : null)}
            </Tab>
        )
    })

    return (

        <Tabs
            defaultActiveKey={0}
            id="0"
            className="d-flex justify-content-center mt-3 mb-3"
        >
            {loadTabWithGraphs()}
        </Tabs>
    );
})