import {
  NOTICIA_LIST_REQUEST,
  NOTICIA_LIST_REQUEST_SUCCESS,
  NOTICIA_LIST_REQUEST_FAIL,
  NOTICIA_DETAIL_REQUEST,
  NOTICIA_DETAIL_SUCCESS,
  NOTICIA_DETAIL_FAIL,
} from "../constants/noticiaConstants";

export const NoticiaListReducer = (state = { noticias: [] }, action) => {
  switch (action.type) {
    case NOTICIA_LIST_REQUEST:
      return { loading: true, noticias: [] };
    case NOTICIA_LIST_REQUEST_SUCCESS:
      return {
        loading: false,
        noticias: action.payload.results,
        page: action.payload.current,
        pages: action.payload.num_pages,
      };
    case NOTICIA_LIST_REQUEST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const NoticiaDetailReducer = (state = { noticia: {} }, action) => {
  switch (action.type) {
    case NOTICIA_DETAIL_REQUEST:
      return { loading: true, noticia: {} };
    case NOTICIA_DETAIL_SUCCESS:
      return {
        loading: false,
        noticia: action.payload,
      };
    case NOTICIA_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
