import { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'

import { graficoList } from '../actions/graficoActions'

export const useGraficosHook = () => {
    const dispatch = useDispatch();
    const graficosList = useSelector((state) => state.graficoList);

    const { graficos, loading, error } = graficosList;

    useEffect(() => {
        dispatch(graficoList());
    }, [dispatch]);
    
    const getGraficosByCampo = (campoNombre) => {
        return (graficos.filter(grafico => 
            grafico.campo.nombre === campoNombre)
        )}

  return {
    graficos,
    getGraficosByCampo,
    loading,
    error
  }
}
