import { useState, useEffect } from "react";
import axios from "axios";
export const useCampos = () => {

const [campos, setCampos] = useState([]);
const [loading, setLoading] = useState(true);
const [error, setError] = useState('');

const camposUrl = `/api/tableros/campos`;
const getCampos = () => {
    try{           
        axios.get(camposUrl).then((response) => {
            setLoading(true)
            setCampos(response.data.results);
            setLoading(false);
        });
    }catch(err){
        setLoading(false);
        setError(err);
    }
    
}

useEffect(() => {
    getCampos()
    console.log(campos)
}, []);

  return {campos, loading, error}
}
