import { Accordion, Col, Row, Container } from 'react-bootstrap';
import { GraficoNavLink } from './GraficoTabsLink'

const GraficoAccordeon = ({ campo, tipos, medidas, graficos, id, campos }) => {
    const { nombre } = campo;
    return (
        <>
            <Accordion defaultActiveKey="1"  className='mt-3' >
                <Accordion.Item className="bg-dark"  eventKey={id}>
                    <Accordion.Header  >{nombre}</Accordion.Header>
                    <Accordion.Body className="bg-dark">
                        <Row>
                            <GraficoNavLink
                                tipos={tipos}
                                graficos={graficos}
                                campos={campos}
                                nombre = {nombre}
                            />
                            <hr className='mb-3' />
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}

export default GraficoAccordeon;