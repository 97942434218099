import React from 'react'

export const Iframe = ({ url }) => {
    return (
        <div className="ratio ratio-16x9 mb-3">
            <iframe className="embed-responsive-item" height="30%" defer loading="lazy"

                src={url} allowFullScreen>
            </iframe>
        </div>
    )
}
