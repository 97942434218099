import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";


function ContactForm() {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [loading, setLoading] = useState(false)

  
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
const csrftoken = getCookie('csrftoken');
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    const config = {
      headers: {
        "Content-Type": "application/json",
        'X-CSRFToken': csrftoken
      },
    };
    const data = {
      nombre,
      email,
      mensaje,
    };

    axios
      .post("/api/contacto/", data, config)

      .then((res) => {
        Swal.fire({
          title: "Gracias por contactarnos!",
          text: "En breve nos pondremos en contacto contigo",
          icon: "success",
        });
        setNombre("");
        setEmail("");
        setMensaje("");
            setLoading(false)

      })
      .catch((err) => {
            setLoading(false)

        Swal.fire({
          title: "Error!",
          text: "No se pudo enviar el mensaje",
          icon: "error",
        });
      });
  };

  return (
    <>
      <section className="relative block py-24 lg:pt-0 bg-blueGray-800">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                <div className="flex-auto p-5 lg:p-10">
                  <h4 className="text-2xl font-semibold">CONTACTO</h4>
                  <p className="leading-relaxed mt-1 mb-4 text-blueGray-500">
                    Haz tus consultas sobre el proyecto y te responderemos a la
                    brevedad
                  </p>
                  <Form onSubmit={handleSubmit}>
                    <div className="relative w-full mb-3 mt-8">
                      <Form.Group>
                        <Form.Label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="full-name"
                        >
                          Nombre
                        </Form.Label>
                        <Form.Control
                          type="nombre"
                          htmlFor="full-name"
                          placeholder="Ingrese su nombre y apellido"
                          value={nombre}
                          required
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={(e) => setNombre(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="relative w-full mb-3 mt-8">
                      <Form.Group>
                        <Form.Label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="email"
                        >
                          EMAl
                        </Form.Label>
                        <Form.Control
                          type="email"
                          htmlFor="email"
                          required
                          placeholder="Ingrese su email"
                          value={email}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={(e) => setEmail(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="relative w-full mb-3">
                      <Form.Group>
                        <Form.Label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="message"
                          required
                        >
                          Mensaje
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="4"
                          htmlFor="message"
                          cols="80"
                          value={mensaje}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Escribe un mensaje..."
                          onChange={(e) => setMensaje(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="text-center mt-6">
                      <Button
                        type="submit"
                        id="btnSubmit"
                        disabled={loading}
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      >

                        {loading ? <i className="fas fa-cog fa-spin"></i> : "Enviar Mensaje" }
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactForm;
