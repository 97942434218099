import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/styles/noticias.css";
import Noticia from "../../components/website/Noticia";
import { noticiaList } from "../../actions/noticiaActions";
import Paginate from "components/website/Paginate";
import SearchBox from "components/website/SearchBox";


function NewsScreen({ history }) {
  const dispatch = useDispatch();
  let search = history.location.search;
  const listNoticias = useSelector((state) => state.noticiasList);
  const { error, loading, noticias, page, pages } = listNoticias;

  useEffect(() => {
    dispatch(noticiaList(search));
  }, [dispatch, search]);

  return (
    <>
      <main className="container-fluid mb-5 mt-3 ">
        {loading ? (
          <Loader />
        ) : error ? (
          <h3>
            Error: <Message variant="danger">{error} </Message>
          </h3>
        ) : (
          <div className="row mb-5">
            <div className="col-md-12 mb-5">
                  <section className="details-card bg-blueGray-200">
                <div className="col-md-12">
                  <h1 className="display-4 font-italic text-center ">
                    <strong>Noticias</strong>{" "}
                  </h1>
                </div>
                <div className="col-md-8 offset-2 ">
                  <SearchBox />
                </div>
                <div className="container">
                  <div className="row">
                    {noticias.map((noticia) => (
                      <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 mt-3 d-flex align-items-stretch">
                        <Noticia noticia={noticia} />
                      </div>
                    ))}
                  </div>
                  <Row className="mt-3">
                    <Col md={4} sm={12} xs={12}>
                      <Paginate page={page} pages={pages} search={search} />
                    </Col>
                  </Row>
                </div>
              </section>

            </div>
          </div>
        )}
      </main>
    </>
  );
}

export default NewsScreen;
