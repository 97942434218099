import axios from "axios";
import {
  NOTICIA_LIST_REQUEST,
  NOTICIA_LIST_REQUEST_SUCCESS,
  NOTICIA_LIST_REQUEST_FAIL,
  NOTICIA_DETAIL_REQUEST,
  NOTICIA_DETAIL_SUCCESS,
  NOTICIA_DETAIL_FAIL,
} from "../constants/noticiaConstants";

export const noticiaList =
  (search = "") =>
  async (dispatch) => {
    try {
      // se activa el primer state o reducer:
      dispatch({ type: NOTICIA_LIST_REQUEST });

      // se activa el segundo state o reducer con los datos:
      const { data } = await axios.get(`/api/noticias/${search}`);
      dispatch({ type: NOTICIA_LIST_REQUEST_SUCCESS, payload: data });
      console.log(data + "from actions");
    } catch (error) {
      console.log(error);

      //se asegura que entregue un error en la respuesta
      dispatch({
        type: NOTICIA_LIST_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const noticiaDetail = (id) => async (dispatch) => {
  try {
    dispatch({ type: NOTICIA_DETAIL_REQUEST });

    const { data } = await axios.get(`/api/noticias/${id}`);
    dispatch({ type: NOTICIA_DETAIL_SUCCESS, payload: data });
    console.log(data);
  } catch (error) {
    dispatch({
      type: NOTICIA_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
