import React, { memo } from "react";
import ContactForm from "../../components/website/ContactForm";
import imgIndex from "../../assets/img/cherries_index.png";

function HomeScreen() {
    return (
        <>
            <main>
                <div className="row">
                    <div className="col-md- 12 col-sm-12 col-xs-12">
                          <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-25">
                    <div className="absolute top-0 w-full h-full bg-center bg-cover img-responsive"
                        style={{
                            backgroundImage:
                                `url(${imgIndex})`,
                        }}
                    >
                        <span
                            id=""
                            className="w-full h-full absolute opacity-75 bg-black"
                        ></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full px-4 ml-auto mr-auto ">

                                <div className="pr-12 mt-12">

                                    <h1 className="text-white font-semibold text-2xl mr-3 ">
                                        Transferencia y adopción de Tecnologías para la Gestión de
                                        Riesgo en el Proceso Productivo de la Cereza
                                    </h1>
                                    <p className="mt-4 text-lg text-blueGray-200">
                                        Hacia una Agricultura de Precisión para la Región de
                                        O’Higgins

                                    </p>
                                    <br />
                                    <i className="text-white">
                                        Proyecto financiado con el Fondo de Innovación Para la
                                        Competitividad Regional del Gobierno Regional del Libertador
                                        General Bernardo O’Higgins y su Consejo Regional.
                                        FIC Código IDI 40027312-0
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-blueGray-200 fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>
                </div>
                <section className="pb-20 bg-blueGray-200 -mt-24">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap">
                            <div className="lg:pt-12 pt-6 col-md-4 col-xs-12 col-sm-12 px-4 text-center">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div
                                            className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                                            <i className="fa fa-cubes"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">
                                            Objetivo general del proyecto
                                        </h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Buscamos la incorporación de sistemas inteligentes
                                            para el pronóstico, monitoreo y adopción de técnicas de agricultura
                                            de precisión para el rubro de la cereza en la Región de O’Higgins.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="lg:pt-12 pt-6 col-md-4 col-sm-12 col-xs-12 px-4 text-center">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div
                                            className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                                            <i className="fas fa-retweet"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">
                                            Enfoque en la cereza
                                        </h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            En el mercado, las grandes empresas relacionadas con el cultivo
                                            de uva han sido lideres en la incorporación de las técnicas de AP,
                                            en menor medida lo siguen sectores experimentales como el nogal y manzanas.
                                        </p>
                                        <p><i className="fa-solid fa-cherries"></i> La cereza actualmente es un mercado
                                            en crecimiento</p>
                                    </div>
                                </div>
                            </div>

                            <div className="lg:pt-12 pt-6 col-md-4 col-sm-12 col-xs-12 px-4 text-center">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                    <div className="px-4 py-5 flex-auto">
                                        <div
                                            className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                                            <i className="fa-solid fa-arrows-to-circle"></i>
                                        </div>
                                        <h6 className="text-xl font-semibold">Nuestro enfoque</h6>
                                        <p className="mt-2 mb-4 text-blueGray-500">
                                            Implementamos una red basada en el Internet de las Cosas, IoT, al interior
                                            de los campos de cereza de la región, automatizando el proceso de
                                            recolección
                                            de datos en tiempo real de variables ambientales en diferentes puntos de
                                            cada huerto.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center mt-32">
                            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                                <div
                                    className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                                    <i className="fa fa-tree text-xl"></i>
                                </div>
                                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                    ¿Por qué la cereza?
                                </h3>
                                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                                    Es un mercado en crecimiento y donde los frutos son altamente demandados,
                                    especialmente por el mercado asiático. Cerca del 90% de las exportaciones
                                    de la cereza producida en Chile termina en los puertos de China. Los suelos
                                    y el clima de la Región de O’Higgins son un privilegio para el cultivo de la cereza.
                                    De allí que sus productores han sabido reaccionar aumentando un 175% su superficie
                                    plantada
                                    en los últimos 10 años y enfocado sus esfuerzos para ser oportunos en la entrega de
                                    cerezas
                                    hacia los puertos orientales.
                                </p>
                                <a target="_blank"
                                    href="https://bibliotecadigital.odepa.gob.cl/bitstream/handle/20.500.12650/71122/Ohiggins202109.pdf"
                                    className="font-bold text-blueGray-700 mt-8">
                                    Revisar datos estadisticos
                                </a>
                            </div>

                            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-light-500">
                                    <img
                                        alt="..."
                                        src={require("assets/img/guindas_3.jpg").default}
                                        className="w-full align-middle rounded-t-lg"
                                    />
                                    <blockquote className="relative p-8 mb-4">
                                        <h4 className="text-xl font-bold text-dark">Contexto</h4>
                                        <p className="text-md font-light mt-2 text-dark">
                                            Una vía para mejorar la calidad es la Agricultura de Precisión.
                                            Este concepto busca la incorporación de soluciones tecnológicas
                                            adoptando desde el principio de mejora continua.
                                        </p>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* OBJETIVOS */}
                <section className="relative py-20">
                    <div
                        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-white fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>

                    <div className="container mx-auto px-4 ">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full md-8 ml-auto mr-auto px-4">
                                <div className="md-8 pr-8">
                                    <h3 className="text-3xl font-semibold">Objetivos del proyecto</h3>
                                    <p>Investigador responsable, Dr. Cristóbal Quiñinao
                                        <i className="mt-8 text-lg leading-relaxed text-blueGray-500">

                                            “Si bien estamos impulsando un prototipo para ayudar a la estimación de
                                            rendimiento de la cereza utilizando herramientas de la agricultura
                                            inteligente,
                                            nuestra mayor intención es lograr una vía amigable para la incorporación de
                                            la tecnología.
                                            Buscamos respetar la cultura del campo en la región, por eso partimos con un
                                            análisis
                                            sociológico del sector. Queremos desarrollar la tecnología a partir de las
                                            particularidades de nuestra región y no reemplazar lo que ya funciona,
                                            si no que ponernos al servicio de los productores y cocrear una solución ad
                                            hoc.”
                                        </i></p>
                                    <ul className="list-none mt-6">
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span
                                                        className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="far fa-paper-plane"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        Describir los métodos de predicción de cosecha.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block py-1 px-2
                           uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="far fa-paper-plane"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        dentificar las variables ambientales relevantes para monitorear.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span
                                                        className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="far fa-paper-plane"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        Automatizar la recolección de datos y alojarlos en la red de la
                                                        Universidad de O´Higgins.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span
                                                        className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="far fa-paper-plane"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        Pronosticar rendimiento de las cerezas en precosecha.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="flex items-center">
                                                <div>
                                                    <span
                                                        className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                        <i className="far fa-paper-plane"></i>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h4 className="text-blueGray-500">
                                                        Transferir modelo de predicción de cosecha y estrategias de
                                                        control de riesgo a los productores regionales.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* RESULTADOS */}
                    </div>
                </div>


                <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
                    <div
                        className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-blueGray-100 fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>

                    <div className="container mx-auto">
                        <div className="flex flex-wrap items-center">
                            <div className="w-10/12 col-md-4 lg:w-6/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
                                <div
                                    className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-light-500">
                                    <img
                                        alt="..."
                                        src={require("assets/img/drone.jpeg").default}
                                        className="w-full align-middle rounded-t-lg"
                                    />
                                    <blockquote className="relative p-8 mb-4">
                                        <h4 className="text-xl font-bold text-dark">
                                            Herramienta útil si eres productor
                                        </h4>
                                        <p className="text-md font-light mt-2 text-dark">
                                            El proyecto despliega sensores en cuatro zonas de la región.
                                            En el cierre del proyecto se abrirán cupos para participar de días
                                            de campo para analizar la tecnología en terreno. Las postulaciones
                                            se encuentran abiertas al correo <strong><a
                                                className="text-blueGray-500 text-"
                                                href="mailto:cerezas@uoh.cl">cerezas@uoh.cl.</a></strong>
                                        </p>
                                    </blockquote>
                                </div>
                            </div>

                            <div className="w-full col-md-8 px-4">
                                <div className="flex flex-wrap">
                                    <div className="w-full md:w-6/12 px-4">
                                        <div className="relative flex flex-col mt-4">
                                            <div className="px-4 py-5 flex-auto">
                                                <div
                                                    className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                    <i className="fa fa-map"></i>
                                                </div>
                                                <h6 className="text-xl mb-1 font-semibold">
                                                    Modelo productivo regional y comunal de volumen de
                                                    cosecha
                                                </h6>
                                                <p className="mb-4 text-blueGray-500">
                                                    Dispondremos de mapas dinámico intraprediales a partir de datos
                                                    de la red LoraWAN desplegada en los campos, corregidas con las
                                                    herramientas
                                                    que nos entrega el sistema
                                                    <strong><a href="https://www.saia.cl"
                                                        target="_blank"> www.saia.cl</a> </strong>
                                                    del Centro de Estudios de Fruticultura Avanzada, CEAF.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="relative flex flex-col min-w-0">
                                            <div className="px-4 py-5 flex-auto">
                                                <div
                                                    className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                    <i className="fas fa-drafting-compass"></i>
                                                </div>
                                                <h6 className="text-xl mb-1 font-semibold">
                                                    Prototipo para el conteo de frutos
                                                </h6>
                                                <p className="mb-4 text-blueGray-500">
                                                    Una tecnología que nos permitirá trabajar la precisión para el
                                                    pronóstico del rendimiento en los campos, es el monitoreo con
                                                    captura
                                                    de imágenes hiperespectrales. Esta herramienta podrá detectar y
                                                    hacer
                                                    conteo automatizado de las estructuras que necesitemos de las
                                                    plantas.
                                                    Nuestro prototipo está enfocado para conteo de cerezas en
                                                    precosecha.

                                                </p>
                                                <div
                                                    className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                                    <img
                                                        alt="..."
                                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                                        src={require("assets/img/prototipo.png").default}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-6/12 px-4">
                                        <div className="relative flex flex-col min-w-0">
                                            <div className="px-4 py-5 flex-auto">
                                                <div
                                                    className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                                    <i className="fa fa-signal"></i>
                                                </div>
                                                <h6 className="text-xl mb-1 font-semibold">
                                                    Red de transmisión de datos
                                                </h6>
                                                <p className="mb-4 text-blueGray-500">
                                                    La red que desplegamos en los campos se sostiene gracias a la
                                                    tecnología Lora. Esta tecnología nos permite una gran cobertura en
                                                    el
                                                    huerto a costa de un ancho de banda muy bajo. Gracias al protocolo
                                                    de
                                                    comunicación LoraWAN logramos trabajar los datos de terreno para el
                                                    algoritmo de pronóstico.
                                                </p>
                                                <div
                                                    className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                                    <img
                                                        alt="..."
                                                        className="align-middle border-none max-w-full h-auto rounded-lg"
                                                        src={require("assets/img/red.png").default}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*
                <section className="block relative z-1 bg-blueGray-600">
                    <div className="container mx-auto">
                        <div className="justify-center flex flex-wrap">
                            <div className="w-full lg:w-12/12 px-4  -mt-24">
                                <div className="flex flex-wrap  mt-3">
                                    <div className="w-full lg:w-4/12 px-4">
                                        <h5 className="text-xl font-semibold pb-4 text-center text-white">
                                            Mapa Interactivo
                                        </h5>
                                        <Link to="/auth/login">
                                            <div
                                                className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                                <img
                                                    alt="..."
                                                    className="align-middle border-none max-w-full h-auto rounded-lg"
                                                    src={require("assets/img/HEATMAP.jpg").default}
                                                />
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="w-full lg:w-4/12 px-4">
                                        <h5 className="text-xl text-white font-semibold pb-4 text-center">
                                            Dashboard de estadisticas
                                        </h5>
                                        <Link to="/profile">
                                            <div
                                                className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                                <img
                                                    alt="..."
                                                    className="align-middle border-none max-w-full h-auto rounded-lg"
                                                    src={require("assets/img/DASHBOARD.png").default}
                                                />
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="w-full lg:w-4/12 px-4">
                                        <h5 className="text-xl text-white font-semibold pb-4 text-center">
                                            Datos
                                        </h5>
                                        <Link to="/landing">
                                            <div
                                                className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                                <img
                                                    alt="..."
                                                    className="align-middle border-none max-w-full h-auto rounded-lg"
                                                    src={require("assets/img/SENSORES.png").default}
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                */}
                <section className="py-20 bg-blueGray-600 overflow-hidden">
                    <div className="container mx-auto ">
                        <div className="flex flex-wrap justify-center">
                            <div className="col-md-12">
                                <h3 className="text-3xl mb-2 font-semibold leading-normal text-white">
                                    ¿Como proponemos abordar el tema de los costos?

                                </h3>
                                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-400">
                                    Para el monitoreo de las variables ambientales utilizaremos sensores, o “end
                                    devices”, de bajo costo y de una alta durabilidad. Estos funcionan conectados a una
                                    antena que permite a su vez la interconexión de cientos de sensores. Gracias al
                                    alcance de estas antenas, podemos compartir la conexión con más de un agricultor y
                                    disponer de la tecnología en un amplio territorio con una pequeña inversión. El
                                    protocolo de comunicación LoraWAN, que permite capturar los datos, es de libre
                                    acceso.</p>
                                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-400">

                                    Los costos del prototipo para el conteo de cerezas es una inversión mayor. Esta
                                    tecnología será una capacidad instalada en la Universidad de O’Higgins, para
                                    realizar el servicio de conteo de frutos mediante imágenes hiperespectrales.
                                    Quedando a disposición de los pequeños y medianos productores de cereza de la
                                    Región.


                                </p>
                                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-400">

                                </p>

                            </div>

                            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto mt-32 relative">
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-20 relative block bg-blueGray-800">
                    <div
                        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-blueGray-800 fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>

                    <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
                        <div className="flex flex-wrap text-center justify-center">
                            <div className="w-full lg:w-6/12 px-4">
                                <h2 className="text-4xl font-semibold text-white">
                                    Contribución a la Región

                                </h2>
                            </div>
                        </div>
                        <div className="flex flex-wrap mt-12 justify-center">
                            <div className="w-full lg:w-3/12 px-4 text-center">
                                <div
                                    className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                    <i className="fas fa-medal text-xl"></i>
                                </div>
                                <h6 className="text-xl mt-5 font-semibold text-white">
                                    Fortalacemos la produccion en la región
                                </h6>
                                <p className="mt-2 mb-4 text-blueGray-400">
                                    Con este proyecto buscamos entregar una herramienta útil a los productores de
                                    cereza con el fin de facilitar el seguimiento del ciclo fenológico de sus frutales.
                                </p>
                            </div>
                            <div className="w-full lg:w-3/12 px-4 text-center">
                                <div
                                    className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                    <i className="fas fa-poll text-xl"></i>
                                </div>
                                <h5 className="text-xl mt-5 font-semibold text-white">
                                    Análisis de datos
                                </h5>
                                <p className="mt-2 mb-4 text-blueGray-400">
                                    Posibilidad de analizar un nuevo tipo de dato. La información se visualiza de
                                    forma sencilla en un panel de manera continua a través de internet.
                                </p>
                            </div>
                            <div className="w-full lg:w-3/12 px-4 text-center">
                                <div
                                    className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                                    <i className="fas fa-lightbulb text-xl"></i>
                                </div>
                                <h5 className="text-xl mt-5 font-semibold text-white">
                                    Gestión de riesgo con tecnología
                                </h5>
                                <p className="mt-2 mb-4 text-blueGray-400">
                                    Transferimos la tecnología de interpretación de imágenes hiperespectrales y de
                                    visualización de datos ambientales con sensores LoraWAN.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <ContactForm />
            </main>
        </>
    );
}

export default memo(HomeScreen);
