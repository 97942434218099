import axios from "axios";
import {
    GRAFICO_LIST_REQUEST,
    GRAFICO_LIST_REQUEST_SUCCESS,
    GRAFICO_LIST_REQUEST_FAIL
} from "../constants/graficoConstants";
export const graficoList = () => async (dispatch) => {
    try {
      // se activa el primer state o reducer:
      dispatch({ type: GRAFICO_LIST_REQUEST });

      // se activa el segundo state o reducer con los datos:
      const { data } = await axios.get(`/api/tableros/graficos`);
      dispatch({ type: GRAFICO_LIST_REQUEST_SUCCESS, payload: data });
    } catch (error) {
      console.log(error);

      //se asegura que entregue un error en la respuesta
      dispatch({
        type: GRAFICO_LIST_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };