import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Loader = () => {

  return (
    <section className="pt-20 pb-48">
      <div className="container mx-auto px-4 mt-10">
        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-orange-600 bg-orange-200 uppercase last:mr-0 mr-1">
          Cargando...
        </span>
        <p>
          <Skeleton height={30} count={3} />
          <Skeleton count={4} />

          <Skeleton height={40} count={4} />
          <Skeleton count={2} />
        </p>
      </div>
    </section>
  )
};

export default Loader;
