/*eslint-disable*/
import React, { memo, useState } from "react";
import logo_gob from "../../assets/img/logosJuntos.png";
import { Navbar, Container, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import {NavLink } from "react-router-dom";

const NavBarComponent = memo((props) => {
    const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Navbar
        expand="lg"
        bg="light"
        variant="light"
      >
        <Container fluid>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"   />
          <Navbar.Collapse id="responsive-navbar-nav">
            <img
              alt=""
              src={require("assets/img/logo_cerezas_contorno.png").default}
              width="80px"
              height="80px"
              className="d-inline-block align-top"
            />{" "}
            <Nav className="me-auto">
                <Nav.Item>
                    <NavLink  eventKey="1" to="/" className={({ isActive }) => {
                  return `nav-link ${isActive && 'active'}`
                }}>
                  <i className="fa fa-home"> </i> Inicio
                </NavLink>
                </Nav.Item>
                <Nav.Item>
                <NavLink  eventKey="2" to="/quienes-somos" className={({ isActive }) => {
                  return `nav-link ${isActive && 'active'}`
                }}>
                  <i className="fa fa-users"> </i> Quienes Somos
                </NavLink>
                    </Nav.Item>
            </Nav>
            <Nav className="me-auto">
                <Nav.Item>
                <NavLink  eventKey="3" to="/noticias" className={({ isActive }) => {
                  return `nav-link ${isActive && 'active'}`
                }}>
                  <i className="fa fa-newspaper"> </i> Noticias
                </NavLink>
                    </Nav.Item>
                <Nav.Item>
                <NavLink  eventKey="4"  to="/campos" className={({ isActive }) => {
                  return `nav-link ${isActive && 'active'}`
                }}>
                  <i className="fa-solid fa-tractor"> </i> Campos
                </NavLink>
                    </Nav.Item>
            </Nav>
            <Navbar.Brand href="/">
            </Navbar.Brand>
            <Navbar.Brand href="/">
              <img
                alt=""
                src={logo_gob}
                width="300px"
                height="250px"
                className="d-inline-block align-top"
              />{" "}
            </Navbar.Brand>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
)

export default NavBarComponent