import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { noticiaDetail } from "actions/noticiaActions";
import guindas_img from "../../assets/img/team-2-800x800.jpg";
import ReactHtmlParser from "react-html-parser";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";


export default function NoticiaDetailScreen({ match }) {
  const noticiaDetails = useSelector((state) => state.noticiaDetail);
  const { noticia } = noticiaDetails;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(noticiaDetail(match.params.id));
  }, [dispatch]);
  return (
    <>
      <main className="profile-page">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: `url(${guindas_img})`,
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-blueGray-200">
          <div className="container mx-auto px-4">
            {noticia ? <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="text-center mt-12">
                <FacebookShareButton url={window.location.href}>
                  <FacebookIcon size={36} />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href}>
                  <TwitterIcon size={36} />
                </TwitterShareButton>
                <WhatsappShareButton url={window.location.href}>
                  <WhatsappIcon size={36} />
                </WhatsappShareButton>
                <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                  {noticia.titulo}
                </h3>
              </div>

              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <img
                    src={noticia.imagen}
                    className="img-fluid rounded-pill"
                    alt="..."
                  />
                </div>
                <div className="text-center mt-12">
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    <i className=" mr-2 text-lg text-blueGray-400"></i>{" "}
                    {noticia.sub_titulo}
                  </div>
                </div>
                <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                        {ReactHtmlParser(noticia.descripcion)}
                      </p>
                      Fuente: <br />
                      <a
                        href={noticia.fuente}
                        target="_blank"
                        className="font-normal text-lightBlue-500"
                      >
                        {noticia.fuente}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>: null}
          </div>
        </section>
      </main>
    </>
  );
}
