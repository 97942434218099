import React from 'react'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom'
import { CarouselCampo } from './CarouselCampo';
import ReactHtmlParser from "react-html-parser";

export const CardCampo = ({ campo }) => {
    console.log(campo)
    return (
        <>

            <Card bg="dark" key="dark" text="light" border="danger" className="mb-3 ">
                <Card.Header>Campo {campo.nombre}</Card.Header>
                <Card.Body className="relative block h-450-px">
                    <Row>
                        <Col md={6} sm={12}>
                            {campo.imagenes.length > 0 &&
                                <CarouselCampo imagenes={campo.imagenes} />
                            }
                        </Col>
                        <Col md={6} >
                            <Card.Title className="mt-3 mb-3">
                                Carácteristicas del campo
                            </Card.Title>
                            <Card.Text className="mt-3 mb-3">
                                {ReactHtmlParser(campo.caracteristicas)}
                            </Card.Text>
                            {campo.descripcion && (
                                <>
                                    <Card.Title className="mt-3 mb-3">
                                        Descripcion del campo
                                    </Card.Title>
                                    <Card.Text className="mt-3 mb-3">
                                        {ReactHtmlParser(campo.descripcion)}

                                    </Card.Text>
                                </>
                            )}


                            <Link to={`/campos/${campo.nombre}`}
                                role="button"
                                className="btn btn-outline-danger mt-3 mb-3 bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                                Ver dashboard en tiempo real
                                <i className="fa fa-line-chart ml-3"></i>



                            </Link>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>

    );
}
