import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {integranteList} from "../../actions/integranteActions";


function QuienesSomos() {
  const dispatch = useDispatch();

  const integrantesList = useSelector((state) => state.integranteList);
  const { integrantes, loading, error } = integrantesList;

  useEffect(() => {
    dispatch(integranteList());
  }, [dispatch]);

  
  return (
    <>
      <section className="pt-20 pb-48">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center text-center mb-24">
            <div className="w-full lg:w-6/12 px-4">
              <h2 className="text-4xl font-semibold mt-5">
                Equipo UOH
              </h2>
            </div>
          </div>
          <div className="flex flex-wrap">
            {integrantes.map((integrante) => (
            <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div className="px-6">
                <img
                  alt="..."
                  src={integrante.avatar}
                  className="shadow-lg rounded-full mx-auto max-w-120-px"
                />
                <div className="pt-6 text-center">
                  <h5 className="text-xl font-bold">{integrante.nombre}</h5>
                  <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                    {integrante.cargo}
                  </p>
                  <div className="mt-6">
                    <button
                        className="bg-white text-lightBlue-400 mb-3
                        shadow-lg font-normal h-10 w-10
                         items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                      type="button"
                    >
                       <a href={integrante.linkedin}
                      target="_blank"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                    </button>

                  </div>
                </div>
              </div>
            </div>
                ))}

          </div>
        </div>
      </section>
      <section className="block relative z-1 bg-blueGray-600">
        <div className="container mx-auto">
          <div className="justify-center flex flex-wrap">
            <h1 className="text text-white font-semibold">
                  Contamos con el Apoyo Institucional de
                </h1>
            <div className="w-full lg:w-12/12 px-4 ">
              <div className="flex flex-wrap  mt-3">
                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="pb-4 text-center text-white">
                    GOBIERNO REGIONAL O'HIGGINS
                  </h5>
                  <a href="https://www.goreohiggins.cl/" target="_blank">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/gore.png").default}
                      />
                    </div>
                  </a>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="pb-4 text-center text-white">CORE</h5>
                  <a href="https://www.goreohiggins.cl/" target="_blank">
                    <div className="hover:-mt-3 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={
                          require("assets/img/core.png").default
                        }
                      />
                    </div>
                  </a>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="pb-4 text-center text-white">
                    UNIVERSIDAD O'HIGGINS
                  </h5>
                  <a href="http://uoh.cl/" target="_blank">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/uoh_ex.png").default}
                      />
                    </div>
                  </a>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="pb-4 text-center text-white">ICI</h5>
                  <a href="#" target="_blank">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={
                          require("assets/img/ici.png").default
                        }
                      />
                    </div>
                  </a>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="pb-4 text-center text-white">FEDEFRUTA</h5>
                  <a href="http://fedefruta.cl/" target="_blank">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={
                          require("assets/img/fedefruta_1.png").default
                        }
                      />
                    </div>
                  </a>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="pb-4 text-center text-white">CEAF</h5>
                  <a href="http://fedefruta.cl/" target="_blank">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={
                          require("assets/img/ceaf.png").default
                        }
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default QuienesSomos;
