import {
    GRAFICO_LIST_REQUEST,
    GRAFICO_LIST_REQUEST_SUCCESS,
    GRAFICO_LIST_REQUEST_FAIL
} from "../constants/graficoConstants";



export const GraficoListReducer = (state = { graficos: [] }, action) => {

  switch (action.type) {
    case GRAFICO_LIST_REQUEST:
      return { loading: true, graficos: [] };

    case GRAFICO_LIST_REQUEST_SUCCESS:
      return {
        loading: false,
        graficos: action.payload.results,

      };
    case GRAFICO_LIST_REQUEST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};