import {
  INTEGRANTE_LIST_REQUEST,
    INTEGRANTE_LIST_REQUEST_SUCCESS,
    INTEGRANTE_LIST_REQUEST_FAIL

} from "../constants/integranteConstant";

export const IntegranteListReducer = (state = { integrantes: [] }, action) => {

  switch (action.type) {
    case INTEGRANTE_LIST_REQUEST:
      return { loading: true, integrantes: [] };

    case INTEGRANTE_LIST_REQUEST_SUCCESS:
      return {
        loading: false,
        integrantes: action.payload.results,

      };
    case INTEGRANTE_LIST_REQUEST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};