import {
  BrowserRouter as Router,
  Route,
        Switch

} from "react-router-dom";
import HomeScreen from "./screens/website/HomeScreen";
import QuienesSomos from "./screens/website/QuienesSomos";
import NewsScreen from "./screens/website/NewsScreen";
import NotFound from "screens/website/404";
import NoticiaDetailScreen from "./screens/website/NoticiaDetailScreen";
import Graficos from "./screens/website/GraficoScreen"
import { Footer } from "./components";
import NavBarComponent from "./components/website/NavBar";
import { Campos } from "screens/website/Campos";
import {CampoDetail} from "screens/website/CampoDetail";
import ScrollRestoration from 'react-scroll-restoration'

function App() {
  return (
    <Router>

      <ScrollRestoration />
      <NavBarComponent />
                <Switch>

        <Route path={"/"} component={HomeScreen} exact />
        <Route path={"/quienes-somos"} component={QuienesSomos} />
        <Route path="/noticias" component={NewsScreen} />
        <Route path="/noticia/:id" component={NoticiaDetailScreen} />
        <Route path="/graficos" component={Graficos} />
        <Route path="/campos" component={Campos} exact />
        <Route path="/campos/:campo" component={CampoDetail} exact/>
        <Route component={NotFound}  />
</Switch>
      <Footer />



    </Router>
  );
}

export default App;
