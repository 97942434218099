import React from "react";


const  Message = ({ variant, children, show }) => {
  const [showAlert, setShowAlert] = React.useState(true);

  React.useEffect(() => {
    if (showAlert != show) {
      //only update when values are different
      setShowAlert(show); //update the state
    }
  }, [show]);
  return (
    <>
      {showAlert ? (
        <div
          className={`text-white px-6 py-4 border-0 rounded relative mb-4 bg-${variant}-500`}
        >
          <span className="inline-block align-middle mr-8">
            <b className="capitalize"> {children}</b>
          </span>
        </div>
      ) : null}
    </>
  );
}

export default Message;
