import React, { useEffect, useState, memo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'

import { graficoList } from "../../actions/graficoActions";
import GraficoAccordeon from '../../components/GraficoAccordeon'


const Graficos = memo(() => {
    const dispatch = useDispatch();
    const graficosList = useSelector((state) => state.graficoList);

    const { graficos, loading, error } = graficosList;
    const [campos, setCampos] = useState([]);
    const [tipos, setTipos] = useState([])
    const [medidas, setMedidas] = useState([])
    const medidasUrl = `/api/tableros/medidas`
    const tiposUrl = `/api/tableros/tipos`
    const camposUrl = `/api/tableros/campos`


    const getObjects = (url, setStateObject) => {
        axios.get(url).then((response) => {
            setStateObject(response.data.results);
        });
    }

    useEffect(() => {
        dispatch(graficoList());
        getObjects(camposUrl, setCampos)
        getObjects(tiposUrl, setTipos)
        getObjects(medidasUrl, setMedidas)
    }, [dispatch]);



    return (<>
        <header className="mt-24">
            <div className="container mx-auto px-4 ">
                <div className="flex flex-wrap justify-center  text-center shadow-lg p-3 mt-3 bg-body rounded">
                    <div className="w-full lg:w-12/12 px-4">
                        <h2 className="text-4xl font-semibold text text-muted ">
                            GRAFICOS
                        </h2>
                        <p className="text text-muted mb-3">Visualizaciones de los datos entregados por los
                            distintos sensores </p>

                    </div>
                </div>
            </div>
        </header>
        <section className="container- fluid mr-3 ml-3">
            <div className="row">
                <div className="col-md-12">
                    {campos.length > 1
                        && campos.map((campo, id) => (
                            (<GraficoAccordeon
                                key = {id}
                                id = {id}
                                campo = {campo}
                                campos={campos}
                                tipos = {tipos}
                                medidas = {medidas}
                                graficos = {graficos}
                            />)
                        ))}
                </div>
            </div>

        </section>

    </>);
})

export default Graficos;