import {  memo, useMemo } from 'react'
import { useParams } from 'react-router-dom';
import { useCampos } from "../../hooks/useCampos";
import { CampoDetailsComponent } from 'components/CampoDetailsComponent';
import Loader from 'components/Loader';
export const CampoDetail = memo(() => {
    //const {campos} = useGraficosHook({})
    const { campo: campoParam } = useParams()
    const { campos, loading, error } = useCampos()

    const campo =  campos.find(campo => campo.nombre === campoParam)

    console.log(loading)
    return (
        <>
            <main className="profile-page">
            {loading ? <Loader /> :  <CampoDetailsComponent campo={campo} />}
            </main>
        </>
    )
})
