import axios from "axios";
import {
  INTEGRANTE_LIST_REQUEST,
  INTEGRANTE_LIST_REQUEST_SUCCESS,
  INTEGRANTE_LIST_REQUEST_FAIL,

} from "../constants/integranteConstant";

export const integranteList = () => async (dispatch) => {
    try {
      // se activa el primer state o reducer:
      dispatch({ type: INTEGRANTE_LIST_REQUEST });

      // se activa el segundo state o reducer con los datos:
      const { data } = await axios.get(`/api/integrantes/`);
      dispatch({ type: INTEGRANTE_LIST_REQUEST_SUCCESS, payload: data });
    } catch (error) {
      console.log(error);

      //se asegura que entregue un error en la respuesta
      dispatch({
        type: INTEGRANTE_LIST_REQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };