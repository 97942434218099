import React from "react";
import { Link } from "react-router-dom";
import "./notFound.css";

function NotFound() {
  return (
    <>
      <div id="main">
        <div className="fof">
          <Link to="/">
            <button className="btn btn-outline-dark btn-sm mb-3">
              <i className="fas fa-home"></i>
              Regresar al inicio
            </button>
          </Link>
          <hr />
          <h1>Pagina no encontrada 404</h1>
        </div>
      </div>
    </>
  );
}

export default NotFound;
