import Carousel from 'react-bootstrap/Carousel';
import ReactHtmlParser from "react-html-parser";

export const CarouselCampo = ({ imagenes }) => {
  console.log(imagenes)

  return (<Carousel fade  >
    {imagenes ? (imagenes.map((img, id) => (
      <Carousel.Item key={id}>
        <div class="opacity-50 bg-cover"><img

          style={{ 'height': "300px" }}
          className="d-block w-100"
          src={img.imagen}
          alt="..."
        /></div>


      </Carousel.Item>))) : null}

  </Carousel>
  );
}
