import React, { useState, useEffect } from 'react'
import axios from 'axios'
import NotFound from 'screens/website/404'
import ReactHtmlParser from "react-html-parser";
import { useGraficosHook } from 'hooks/useGraficosHook'
import { GraficoNavLink } from './GraficoTabsLink'
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom'


export const CampoDetailsComponent = ({ campo }) => {

    const tiposUrl = `/api/tableros/tipos`
    const [tipos, setTipos] = useState([])
    const { getGraficosByCampo, loading, error } = useGraficosHook()
    let graficosCampo = ""
    const getTipos = () => {
        axios.get(tiposUrl).then((response) => {
            setTipos(response.data.results);
        });
    }
    useEffect(() => {
        getTipos()
    }, [])
    if (campo) {
        graficosCampo = getGraficosByCampo(campo.nombre)
    }

    console.log(tipos)
    return (
        <>
            {!campo ? (<NotFound />) : (
                <>
                    <section className="relative block h-500-px">
                        <div className="absolute top-0 w-full h-full bg-center bg-cover"

                            style={{
                                backgroundImage:
                                    `url(${campo.imagenes.length > 0 && campo.imagenes[0].imagen})`
                            }}
                        >
                            <span
                                id="blackOverlay"
                                className="w-full h-full absolute opacity-50 bg-black"
                            ></span>
                        </div>
                        <div
                            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                            style={{ transform: "translateZ(0)" }}
                        >
                            <svg
                                className="absolute bottom-0 overflow-hidden"
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="text-blueGray-200 fill-current"
                                    points="2560 0 2560 100 0 100"
                                ></polygon>
                            </svg>
                        </div>
                    </section>
                    <section className="relative py-3 bg-blueGray-200">
                        <div className="container mx-auto px-100 mb-5">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                                <div className="px-6">
                                    <div className="flex flex-wrap justify-center">


                                        <div className="w-full lg:w-4/12 px-4 lg:order-1">
                                            <div className="flex justify-center py-4 lg:pt-4 pt-8">
                                                <Link
                                                    className="btn btn-outline primary bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                    to={`/campos`}
                                                >
                                                    <i className="fa fa-arrow-left ml-3"></i>
                                                    {" "}Campos
                                                    
                                                </Link>
                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-12">
                                        <h1 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                                            Campo de {campo.nombre} <i className="fa-solid fa-tractor"></i>

                                        </h1>
                                        <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                                            {ReactHtmlParser(campo.caracteristicas)}
                                        </div>
                                        <div className="mr-4 p-3 text-center">
                                            <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                                                {graficosCampo.length > 0 && graficosCampo.length}
                                            </span>
                                            <span className="text-sm text-blueGray-400">
                                                Gráficos disponibles
                                            </span>
                                        </div>
                                        <div className="mb-5 text-blueGray-600 mt-3">
                                            <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
                                            Region: VI REGION
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative py-3 bg-blueGray-200">
                            <div className="container-fluid">
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <Card bg="dark" text="white">
                                            <Card.Header>Dashboard</Card.Header>
                                            <Card.Body>
                                                <GraficoNavLink
                                                    tipos={tipos}
                                                    graficos={graficosCampo}
                                                    nombre={campo.nombre}
                                                />
                                            </Card.Body>
                                        </Card>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </>
            )}


        </>
    )
}
